.searchInput {
  text-align: center;
}

.banner {
  color: #ffffff;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.results-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 90px;
  justify-content: center;

  min-width: 600px;
  align-self: center;
  max-width: 1300px;
}

.Youtube-logo {
  width: 30px;
  height: 20px;
}

#searchInput {
  width: 900px;
  max-width: 550px;
  padding: 3px;
  align-self: center;
  background-color: #000;
  border: none;
  color: #fff;
}

.footer {
  background-color: #000;
  color: #fff;
  text-align: center;
}

@media (max-width: 500px) {
  #searchInput {
    width: 200px;
    padding: 3px;
  }
  .results-container {
    margin-top: 20px;
  }

  .logo {
    .search-bar {
      justify-content: center;
    }
  }
}
