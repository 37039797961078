.logo {
  margin: 0 0 0 5px;
  display: flex;
  h3 {
    margin: 0;
    font-size: 1.5rem;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    font-weight: 900;
  }
  align-items: center;
  cursor: pointer;
}

.nav-bar {
  top: 0;
  background-color: rgb(32, 32, 32);
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  position: fixed;
}

.faSearch {
  align-items: center;
  width: 35px;
  padding: 7px 10px;
  background-color: rgb(63, 63, 63);
  color: #ffffff;
}

#searchInput {
  padding: 7px;
}

.search {
}
